.nav-wizard{
    margin-top:30px;
    margin-bottom:30px;
}

.nav-wizard li{
    padding-left: 20px;
    padding-right: 20px;
}
.nav-wizard li a{
    background: white;
    border: 1px solid #ffcbc4;
}
.border-left{
    border-left: 1px solid #ffcbc4;
}

.border-top{
    border-top: 1px solid #ffcbc4;
}

.border-bottom{
    border-bottom: 1px solid #ffcbc4;
}

label.control-label{
    font-weight: normal;
}

label.required{
    font-weight: bold;
}
label.required:after{
    content: '*';
    margin-left: 5px;
}

.margin-top-10{
    margin-top:10px;
}
.margin-top-20{
    margin-top:20px;
}

.margin-bottom-10{
    margin-bottom:10px;
}
.margin-bottom-20{
    margin-bottom:20px;
}
.margin-bottom-30{
    margin-bottom:30px;
}
.content-title{
    margin-bottom: 30px;
}

.glyphicons-lg{
    font-size: 30px;
}

.list-group-item.row{
    margin-left: 0px;
    padding-left: 0px;
}

.no-margin.row{
    margin-left: 0px;
    margin-right: 0px;
}

.inline-edit:hover:after{
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ' \270f';
    font-size: 15px;
    color: #888888;
}

.image-selector{
    height: 120px;
}

.tab-content.well {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.dashboard-countdown .panel-body{
    color: white;
    text-shadow: 1px 1px 1px black;
}

.input-group.input-group-sm {
    width: 100%;
}
.input-group-sm .input-sm{
    width: 100%;
    border-radius: 4px !important;
}

.invoice-item.row{
    margin-bottom: 5px;
}

li > .label.label-info {
    margin-top: 8px;
}

.row.no-padding{
    padding: 0;
    margin: 0;
}

/**
 * Typeahead
 ******/
span.twitter-typeahead {
    width: 100%;
}

span.twitter-typeahead .tt-dataset{
    background-color: white;
    border: 1px solid #ffcbc4;
    padding: 10px;
}

span.twitter-typeahead .tt-menu{
    width: 100%;
}

span.twitter-typeahead .tt-dataset .tt-cursor,
span.twitter-typeahead .tt-dataset .tt-selectable:hover,
span.twitter-typeahead .tt-dataset .tt-selectable:focus{
    background-color: #eeeeee;
}

/**
 * Table
 **********/
.table > thead > tr > th > a{
    color: #636b6f;
}

.table > thead > tr > th > a span{
    font-size: 10px;
    margin-left: 10px;
}

.table > tbody > tr.odd {
  background-color: #f9f9f9;
}


/****
 * Logo
 *****/

#logo{
    height: 100px;
}

.navbar-brand #logo_header{
    height: 100%;
    max-height: 34px;
}

.navbar-brand #logo_header_small{
    height: 100%;
    max-height: 34px;
}

.navbar-brand {
    padding: 8px 15px;
    display: table;
}

.navbar-brand > *{
    display: table-cell;
}

.navbar-brand > span {
    vertical-align: bottom;
    font-size: 30px;
    padding-bottom: 4px;
}

/***
 * Backgrounds
 ********/

.row_bg{
    background-repeat: no-repeat;
    background-size: cover;
}
.row_bg_kytica{
    background-image: url('../images/row_bg/row_bg_kytica-2.jpg');
}

.panel-default > .panel-heading {
    background-color: rgba( 255, 255, 255, 0.7);
}


/***
 * Strike
 ********/
.separator {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
}

.separator > span {
    position: relative;
    display: inline-block;
}

.separator > span:before,
.separator > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #eeeeee;
}

.separator > span:before {
    right: 100%;
    margin-right: 15px;
}

.separator > span:after {
    left: 100%;
    margin-left: 15px;
}

/*******
 * edit
 ***********/
.popup-edit,
.inline-edit{
    cursor: pointer;
}


.editable-container.editable-inline,
.editable-container.editable-inline .control-group.form-group{
    width: 100%
}

.editable-container.editable-inline .control-group.form-group > div{
    display: table;
}

.editable-container.editable-inline .editable-input {
    display: table-cell;
    width: 100%;
    padding-right: 10px;
}

.editable-container .form-control{
    width: 100%;
}

.editable-wysihtml5{
    width:100%;
    color: black;
    background-color: white;
}

.editable-container.editable-inline .editable-buttons{
    display: table-cell;
}


/**************
 * TO DO list
 ***************/
.to-do > li {
    width: 100%;
    padding-right: 10px;
}


.to-do > li > a {
    color: #252525;
}

.to-do > li label {
    font-weight: normal;
}

.to-do > tr.success .to-do-text,
.to-do > li.list-group-item-success .to-do-text {
    text-decoration: line-through;
}

li#newItem input {
    width: 100%;
    border: 0px;
}

#to-do-list .editable-container.editable-inline{
    width: 75%;
    float: left;
}

#to-do-list .control-group.form-group > div{
    width: 100%;
}
#to-do-list input.form-control.input-sm{
    padding-right: 24px;
    border: 0px;
    background: transparent;
    box-shadow: 0px 0px 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 20px;
}

/*****
 * Croppic
 ******/
.hover-button-content .hover-button {
    position: absolute;
    margin: 10px;
    display: none;
    right: 15px;
}
.hover-button-content:hover .hover-button{
    display: block;
}

/******
 * File list
 ************/
.file-list img.img-thumbnail {
    margin-bottom: 20px;
}

.file-list > div > a.trash-button {
    display:none;
}
.file-list > div:hover > a.trash-button {
    display:block;
    position: absolute;
}

/********
 * Sub-head tooltip
 ****************/
.subhead-tooltip {
    display: table;
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    width: 100%;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.subhead-tooltip > * {
    display: table-cell;
}
.subhead-tooltip > div:first-child {
    padding-right: 10px;
}


/********************
 * Widget
 **********************/

.widget-left {
	height: 80px;
	padding-top: 10px;
	text-align: center;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 32px;
}

.widget-right {
	text-align: left;
	line-height: 1.6em;
	margin: 0px;
	padding: 20px;
	height: 80px;
	color: #999;
	font-weight: 300;
	background: #fff;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
    overflow: hidden;
}

@media (max-width: 768px) {
	.widget-right {
	width: 100%;
	margin: 0;
	text-align: center;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	}
}

@media (max-width: 768px) {
	.widget-left {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	}
}

.widget-right .text-muted {
	color: #9fadbb;
}
.widget-right .large {
	color: #5f6468;
}

.panel-widget {
    border: 1px solid #ffcbc4;
	background: #fff;
}

.panel-widget .widget-left {
    background: #C22B53;
    color: #fff;
}

.panel .panel-body .label {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.panel-gray .widget-left {
    background: #b6b6b6;
}
.panel.warning .widget-left {
  background-color: #f7ecb5;
}


.section-body #place-description,
#layout_content #place-description,
.section-body #agency-description,
#layout_content #agency-description {
    text-align: justify;
}